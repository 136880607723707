import React, { Component } from 'react';

import Preview from './Preview';

import './themes.css';
import './style.css';

const DESKTOP_WIDTH_THRESHOLD = 750;

class Work extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isDesktop: window.innerWidth >= DESKTOP_WIDTH_THRESHOLD,
        }
    }

    addResizeListener = () => {
        if (this.state.isDesktop) return;

        window.addEventListener('resize', this.resizeListener);
    }

    resizeListener = () => {
        if (this.state.isDesktop) {
            window.removeEventListener('resize', this.resizeListener);
            return;
        }

        // flag prevents the check function to run simultaneously if/when the event fires before it's done
        if (!this.checkingSize) {
            window.requestAnimationFrame(() => {
                var width = window.innerWidth;
                // console.log(width);
                if (width >= DESKTOP_WIDTH_THRESHOLD) this.setState({
                    isDesktop: true
                })
                this.checkingSize = false;
            });
        }

        // flip the flag so that the above function won't run again until it's finished
        this.checkingSize = true;
    }


    // LIFECYCLE
    componentDidMount = () => {
        if (!this.state.isDesktop) this.addResizeListener();
    }


    // RENDERING
    render() {
        return (
            <section className="work" id="work" name="work">
                <Preview article="mathspace" />
                <Preview article="stan" />
                <Preview article="optus" />
                <Preview article="woolies" />
                <Preview article="burst" />
                <Preview article="beetroot" />
                <Preview article="rest" />
                <Preview article="att" />
                <Preview article="fa" />
                <Preview article="playstation" />
                <Preview article="thumbcinema" />

                {/* <Item
                 {...this.state}
                 id="scrum"
                 title="Scrum Postcards"
                 icon={require('./images/scrum/icon.png')}
                 workType="Design & Prepress"
                 galleries={[
                     {
                         device: 'poster',
                         images: [
                             { src: require('./images/scrum/timebox.jpg') },
                             { src: require('./images/scrum/deployment.jpg') },
                             { src: require('./images/scrum/backlog.jpg') },
                             { src: require('./images/scrum/zombie-stories.jpg') },
                             { src: require('./images/scrum/user-testing.jpg') },
                             { src: require('./images/scrum/refactoring.jpg') },
                         ]
                     },
                     {
                         device: 'poster',
                         images: [
                             { src: require('./images/scrum/backlog.jpg') },
                             { src: require('./images/scrum/user-testing.jpg') },
                             { src: require('./images/scrum/deployment.jpg') },
                             { src: require('./images/scrum/timebox.jpg') },
                             { src: require('./images/scrum/zombie-stories.jpg') },
                             { src: require('./images/scrum/refactoring.jpg') },
                         ]
                     },
                     {
                         device: 'poster',
                         images: [
                             { src: require('./images/scrum/zombie-stories.jpg') },
                             { src: require('./images/scrum/timebox.jpg') },
                             { src: require('./images/scrum/deployment.jpg') },
                             { src: require('./images/scrum/backlog.jpg') },
                             { src: require('./images/scrum/refactoring.jpg') },
                             { src: require('./images/scrum/user-testing.jpg') },
                         ]
                     },
                 ]}
                 intro="An illustrated series of postcards depicting sprint retrospective actions, used as office decoration"
                >
                    <p>As part of sprint retrospectives at PlayStation, action items are often generated to address any changes or additions to processes or workflows. This series captures a number of ongoing actions, and are used as decoration around the office.</p>
                    <p>They are printed as real postcards, and were occasionally mailed across the pond to other teams engaged in the project.</p>
                </Item>

                <Item
                 {...this.state}
                 id="bnt"
                 title="Bras N Things"
                 icon={require('./images/bnt/icon.png')}
                 workType="Web Design & Development"
                 galleries={[
                     {
                         device: 'browser',
                         domain: 'bnt.com.au',
                         images: [
                             { src: require('./images/bnt/desktop-home.jpg') },
                             { src: require('./images/bnt/desktop-info.jpg') },
                             { src: require('./images/bnt/desktop-facebook.jpg') },
                         ]
                     }
                 ]}
                 intro="A campaign to launch Bras N Things’ Facebook page with a bang. 42,000 Likes ain’t bad."
                >
                    <p>The project was a multi-channel effort, including a microsite, Facebook app, eDMs and in-store print material.</p>
                    <p>The voting mechanic in particular was given a great deal of thought in order to capitalise on the viral nature of social media. The Facebook 'Like' button was used to vote in the competition. This meant that any vote given would be pushed to each users’ profile. This would be spread to friends of this user via their News Feed, creating a rudimentary viral spread.</p>
                    <p>After 10 weeks of the competition running, we’d achieved over 42,000 Likes — making it the fastest growing Facebook page of any Australian retailer at the time.</p>
                </Item>

                <Item
                 {...this.state}
                 id="barnardos"
                 title="Barnardo's"
                 icon={require('./images/barnardos/icon.png')}
                 workType="Infographic Design"
                 galleries={[
                     {
                         device: 'poster',
                         images: [
                             { src: require('./images/barnardos/02.jpg') },
                             { src: require('./images/barnardos/03.jpg') },
                             { src: require('./images/barnardos/04.jpg') },
                             { src: require('./images/barnardos/01.jpg') },
                         ]
                     },
                     {
                         device: 'poster',
                         images: [
                             { src: require('./images/barnardos/01.jpg') },
                             { src: require('./images/barnardos/02.jpg') },
                             { src: require('./images/barnardos/03.jpg') },
                             { src: require('./images/barnardos/04.jpg') },
                         ]
                     },
                     {
                         device: 'poster',
                         images: [
                             { src: require('./images/barnardos/02.jpg') },
                             { src: require('./images/barnardos/03.jpg') },
                             { src: require('./images/barnardos/01.jpg') },
                             { src: require('./images/barnardos/04.jpg') },
                         ]
                     },
                 ]}
                 intro="An infographic series illustrating the scope of child poverty in the UK"
                >
                    <p>Barnardo's is a charity that provides a comprehensive range of local support, counselling, fostering, adoption, education, residential and training services for children, young people and their families. The charity works to transform the lives of vulnerable children and young people, and aims to enable them to fulfil their potential.</p>
                    <p>In recent years, Barnardo’s has campaigned heavily against child poverty. This series of infographics was designed using a comprehensive set of data for use in an online campaign.</p>
                </Item> */}
            </section>
        );
    }
}

export default Work;
