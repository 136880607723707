import React from 'react';
import { Link, ScrollRestoration, Outlet, useLocation, useLoaderData } from 'react-router-dom';
import { Helmet, HelmetData } from 'react-helmet-async';
import ReactGA from 'react-ga4';

import Feedback from './Feedback';
import { Image, Video } from './Visuals';

import './article.css';
// import './articleTransitions.css';

const helmetData = new HelmetData({});

export async function loader({ params }) {
    return params.article || false;
}

export function ArticleContainer() {
    const article = useLoaderData();
    let location = useLocation();

    React.useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search, title: article });
    }, [location, article]);

    // console.log(article);

    if (!article) {
        throw new Response('', {
            status: 404,
            statusText: 'Not found'
        })
    }

    return (
        <div className={'article ' + article}>
            <Outlet />
            <ScrollRestoration />
        </div>
    );
}

const articles = ['mathspace', 'stan', 'optus', 'woolies', 'rest', 'burst','beetroot','att','fa','playstation','thumbcinema'];

export default function Article() {
    const article = useLoaderData();
    // console.log(article);

    if (!articles.includes(article)) {
        throw new Response('', {
            status: 404,
            statusText: 'Not found'
        })
    }

    if (!article) return <div>oh no</div>;
    else if (article === 'mathspace')   return <Mathspace id={article} />
    else if (article === 'stan')        return <Stan id={article} />
    else if (article === 'optus')       return <Optus id={article} />
    else if (article === 'woolies')     return <Woolies id={article} />
    else if (article === 'rest')        return <Rest id={article} />
    else if (article === 'burst')       return <Burst id={article} />
    else if (article === 'beetroot')    return <Beetroot id={article} />
    else if (article === 'att')         return <Att id={article} />
    else if (article === 'fa')          return <Fa id={article} />
    else if (article === 'playstation') return <Playstation id={article} />
    else if (article === 'thumbcinema') return <Thumbcinema id={article} />
}

function ArticleNav(props) {
    var location = useLocation();
    const isPushed = location?.state?.isPushed;
    window.history.replaceState(null, '');

    function GASendExternalLink(label) {
        ReactGA.event(
            'page_view',
            {
                'page_title': props.id + ': ' + label,
                category: 'External'
            }
        )
    }

    return (
        <nav className="article-nav">
            {isPushed ? (
                <Link className="article-nav-link" to={-1}>{'<- Back'}</Link>
            ) : (
                <Link className="article-nav-link" to="/">{'<- Alex'}<span className="article-nav-surname"> Duckmanton</span></Link>
            )}
            
            {props.links &&
                <span>
                    <span className="article-external-preamble">View project for </span>

                    {props.links.map((link, i) => {
                        var splitter = ', ';
                        var isLast = i === props.links.length-1;
                        var isSecondLast = i === props.links.length-2;

                        if (isLast) splitter = '';
                        else if (isSecondLast) splitter = ' and ';

                        var label = link.label;
                        if (isLast) label += ' ↗';

                        return <span key={i}>
                            <Link className="article-external-link" to={link.url} target="_blank" rel="noreferrer" onClick={() => { GASendExternalLink(link.label) }}>{label}</Link>
                            <span>{splitter}</span>
                        </span>
                    })}
                </span>
            }
        </nav>
    )
}

function Header({ id, isPreview, title, description, links }) {
    if (isPreview) return;

    return (
        <>
            <Helmet helmetData={helmetData}>
                <title>{title} | Alex Duckmanton</title>
                <meta name="description" content={description} />
            </Helmet>

            <ArticleNav
                id={id}
                links={links}
            />
        </>
    )
}

function Section(props) {
    // const navigate = useNavigate();
    const path = 'article/' + props.id;
    const linkOptions = {
        state: { isPushed: true }
    };

    if (props.isPreview && !props.showInPreview) return;


    function navigateToArticle(e) {
        // e.preventDefault();
        // if (!document.startViewTransition) {
        //     console.log('Transitions not supported :(');
        //     navigate(path, linkOptions);
        //     return;
        // }

        // document.startViewTransition(() => navigate(path, linkOptions));
    }

    return (
        <section className={'article-section ' + (props.showInPreview ? 'article-section-in-preview' : '')}>
            {props.visual}

            <div className="article-section-content-container">
                <div className="article-section-content">
                    {props.title &&     <h1 className="article-title">{props.title}</h1>}
                    {props.role &&      <h2 className="article-role">{props.role}</h2>}
                    {props.date &&      <h2 className="article-date">{props.date}</h2>}
                    {props.blurb &&     <h3 className="article-blurb">{props.blurb}</h3>}

                    {props.isPreview && props.showInPreview && props.id &&
                        <Link to={path} {...linkOptions} className="article-link" onClick={navigateToArticle}>{'Read more ->'}</Link>
                    }

                    {!props.isPreview &&
                        <>
                            {props.heading && <h4 className="article-heading">{props.heading}</h4>}
                            {props.children}
                        </>
                    }
                </div>

                {props.isPreview && props.showInPreview && props.feedback &&
                    <Feedback items={props.feedback} />
                }
            </div>
        </section>
    )
}

export function Mathspace(props) {
    return (
        <>
            <Header
                {...props}
                title="Mathspace"
                description="I managed a design team of six, while spending half my time on the tools doing research & product design."
            />

            <Section
                {...props}
                showInPreview
                // visual={ <Image alt="A concept for the student workbook in Mathspace" src={{
                //     small: require('./images/mathspace/1.jpg'),
                //     large: require('./images/mathspace/1@2x.jpg')
                // }}/> }
                visual={ <Video align="phone" src={{
                    background: require('./images/mathspace/iphone-x.png'),
                    poster: require('./images/mathspace/workbook-mobile-poster.jpg'),
                    webm: require('./images/mathspace/workbook-mobile.webm'),
                    mp4: require('./images/mathspace/workbook-mobile.mp4'),
                }}/> }
                title="Mathspace"
                role="Head of Design"
                date="2022 --> Present"
                blurb="I managed a design team of six, while spending half my time on the tools doing research & product design."
                heading="Day-to-day work">
                    <p>Mathspace is an online, personalised mathematics learning platform. It tailors mathematics learning pathways for students based on their knowledge gaps. Teachers can assign practice for their students, and review their progress as it aligns to their local curriculum.</p>
                    <p>When I joined, the design team had 4 product designers, and we quickly added 2 illustrators based in the Philippines.</p>
                    <p>As a manager, I worked with designers on the team with direction and feedback on their work, career development, and learning.</p>
                    <p>As Head of Design, I worked closely with our Head of Product, founders, and leadership team on our product’s direction while championing design across the wider org.</p>
                    <p>These responsibilities were split 50/50, with the remainder of my time spent on the tools doing research and product design.</p>
            </Section>

            <Section
                {...props}
                // visual={ <Image hasBorder alt="An internal workshop at Mathspace" src={{
                //         small: require('./images/mathspace/2.jpg'),
                //         large: require('./images/mathspace/2@2x.jpg')
                // }}/> }
                visual={ <Video hasBorder src={{
                    poster: require('./images/mathspace/internal-workshop-poster.jpg'),
                    webm: require('./images/mathspace/internal-workshop.webm'),
                    mp4: require('./images/mathspace/internal-workshop.mp4'),
                }}/> }
                heading="Internal & external workshops">
                    <p>Much of Mathspace is made up of people passionate about teaching and eduction. Most of the sales team, for example, are ex-teachers. This means there’s a wealth of knowledge to be tapped into!</p>
                    <p>To capture this, I ran regular workshops to get all other departments involved in the design process — both as a way to tap them for new ideas, but also to create cheerleaders for features right from the start.</p>
                    <p>For internal workshops, we often had upwards of 20 people attending, which presented all kinds of challenges to keep people contributing without stepping on each others’ toes. What results was a highly efficient method of crowd-sourcing ideas and solutions to form a backlog for the design team.</p>
                    <p>I also ran external workshops with customers, using FigJam as an environment to let them collaborate with their peers. Doing this publicly also generated good-will for the business among our customers. It sent a message that we were listening and being responsive to their needs, and was generally very well received.</p>
            </Section>

            <Section
                {...props}
                visual={ <Image hasBorder alt="Examples of student struggles in the workbook and proposed actions to take" src={{
                        small: require('./images/mathspace/3.jpg'),
                        large: require('./images/mathspace/3@2x.jpg')
                }}/> }
                heading="Solving student struggles">
                    <p>One of the great joys of working on a product that serves teenagers is reading through their feedback and comments. We have multiple touch points that allow this, but one of the areas we knew needed attention was the student workbook.</p>
                    <p>This is where student do most of their maths homework in Mathspace, and anecdotally we knew it was a big source of frustration. </p>
                    <p>It had previously been put into the too-hard basket, with no clear direction of where to start, and a general sense that it was too big of a problem to solve.</p>
                    <p>I sifted through negative comments students had left about the workbook, and went about matching that with their actual work. I’d match comments like “I’m right but it said I was wrong” with what actually happened.</p>
                    <p>Much of the feedback had previously been dismissed because students thought they were right when they had made a mistake. This was often the case, but a pattern started to emerge where it became clear that in many cases, students were being mislead by the UI.</p>
                    <p>With the help of our developers and PMs, I sorted my findings by effort and impact to find a number of fixes that would hit the sweet spot of low effort + high impact.</p>
                    <p>As we were rolling out each fix, we heard from teachers and students that they were noticing improvements. We also saw in our data that our “frustration metrics” had gone down. Both a qualitative and quantitative improvement.</p>
            </Section>

            <Section
                {...props}
                visual={ <Image hasBorder alt="A sampling of interview cards in Dovetail" src={{
                        small: require('./images/mathspace/4.jpg'),
                        large: require('./images/mathspace/4@2x.jpg')
                }}/> }
                heading="Student and teacher interviews">
                    <p>Mathspace offers both paid and free versions of its app to teachers. This gave use two sources of customers to speak with for both general-purpose interviews and more targeted user-testing sessions.</p>
                    <p>Our teachers on the paid version typically came via our sales or support teams when giving direct feedback. I’d also reach out directly to schools that were highly engaged in specific features I was working on.</p>
                    <p>Our freemium teachers were a great source of new users, where we could gain insights into their first-time experience and test features they had little to no familiarity with. Working with the Marketing team, I set up an automated email campaign to new freemium users, so we had a constant stream of customers for our research.</p>
                    <p>All interviews were recorded and organised in Dovetail, where transcripts were tagged using a taxonomy that covered areas like the product lifecycle, individual features, emotion, demographics, and the product journey.</p>
                    <p>This gave us a constantly growing source of insights to pull from whenever we picked up a new item of work. It meant every designer could hit the ground running with research-based designs for a very low investment of their time.</p>
            </Section>

            <Section
                {...props}
                visual={ <Image alt="A concept for the student workbook in Mathspace" src={{
                        small: require('./images/mathspace/5.jpg'),
                        large: require('./images/mathspace/5@2x.jpg')
                }}/> }
                heading="Designing for a GPT-based tutor">
                    <p>The vision of Mathspace has always been to create a tutor-like experience for students learning maths. The goal is to provide the right help at the right time.</p>
                    <p>For questions in the student workbook, Mathspace has many pre-defined paths that students can go down. Students can get help that is specific to the step they’re on. Given the variety of paths a student can take while solving a problem, it can be very labor-intensive to set this up, and inevitably there are gaps for students who go down unexpected paths, or make unexpected mistakes.</p>
                    <p>With the rapid progress of GPT, we saw a clear opportunity to make use of the flexibility of a large language model to fill the gaps of our existing setup. Combining this with the smarts of the existing math engine could supercharge this vision of a tutor for every student.</p>

                    <Section
                        {...props}
                        heading="Prototyping a vision">
                            <p>This became a focus for the majority of our team. I created some rapid, high-level designs and prototypes to help steer this vision from the start.</p>
                            <p>As engineers built up a workable implementation, we iterated together to refine a feature-set that could be shipped to real students.</p>
                            <p>Our initial version simply replaced our existing pre-baked hints with GPT-generated ones, but we quickly evolved that to include dynamic prompting and an open input where students could interact directly with the AI.</p>
                    </Section>

                    <Section
                        {...props}
                        heading="Learnings from testing and interviews">
                            <p>As it was such a new area of exploration, both for us and schools, we initially released this as a closed-beta for schools who actively wanted to try it. Our Head of Product and I met directly with teachers to ensure we fully understood any concerns they might have had before rolling out too widely.</p>
                            <p>One early concern was around giving full access to GPT to students. We used insights gained from these conversations (and reviewing chat logs) to refine tools for moderation and control, to prevent conversations from falling off the rails.</p>
                    </Section>
            </Section>

            <Section
                {...props}
                visual={ <Image alt="A selection of chats with Milo" src={{
                        small: require('./images/mathspace/6.jpg'),
                        large: require('./images/mathspace/6@2x.jpg')
                }}/> }
                heading="Unpredictability and delight with GPT">
                    <p>In the beta period for our GPT tutor prototypes, we noticed several areas where we’d refinement: keeping things on-topic, moderation, robustness, and fun.</p>

                    <Section
                        {...props}
                        heading="Keeping things on topic">
                            <p>With an open input box, kids will be kids. We took great care to review all messages as they came through in the early stages, in the hopes that we could learn from them. One of our early lessons was that kids love to test the boundaries of new things, and our chat experience was no different.</p>
                            <p>I worked with our engineers to find the right balance between keeping conversations on-track, without making things feel too strict.</p>
                    </Section>

                    <Section
                        {...props}
                        heading="Moderation">
                            <p>In a similar vein, we also noticed a number of students pushing things too far. As this was a concern from many of our beta teachers, we looked to address this with a simple moderation system.</p>
                            <p>Messages would be available for teachers to review whenever they liked, but we would also proactively send problematic messages to teachers so they could act on them accordingly.</p>
                    </Section>

                    <Section
                        {...props}
                        heading="Robustness">
                            <p>We could only support some types of questions in the beginning, so needed a way to explain this to students that felt natural. It needed to be clear what Milo was capable of, so that they understood what was possible.</p>
                            <p>We opted to use the chat format to do what it did best — communicate! Milo would be up-front about what he could and couldn’t do, so students were always in the loop.</p>
                            <p>To protect Milo’s character as a fun and helpful tutor, I added a robot character as a way of delivering bad news. This way Milo could always be seen as the fun one, and the robot could be someone you love to hate.</p>
                    </Section>

                    <Section
                        {...props}
                        heading="Fun">
                            <p>Because we were introducing a more personable way of interacting with the platform, we found that a lot of students wanted to interact in a more casual way.</p>
                            <p>A lot of students would ask Milo about what it’s like to be a dog, and whether he wanted a treat!</p>
                            <p>We already dress up our dog character Milo for special events, and so I explored ways for us to bring this kind of fun to the chat as well.</p>
                    </Section>
            </Section>

            <Section
                {...props}
                visual={ <Image alt="Teacher comments from our NPS survey" src={{
                        small: require('./images/mathspace/7.jpg'),
                        large: require('./images/mathspace/7@2x.jpg')
                }}/> }
                heading="Learning from unhappy teachers">
                    <p>Another focus for me at Mathspace was to find ways to improve our teacher experience. It’s a platform that is very feature-rich, and had grown a lot over time. As with many platforms like this, it grew to a point where it became more difficult to use, especially for new users.</p>

                    <Section
                        {...props}
                        heading="The research">
                            <p>From visiting schools and speaking with teachers, it also became clear that most users weren’t even aware of some of our flagship features. These were additions to the platform that had undergone significant investment, which was being wasted because of a poor user experience.</p>
                            <p>Every month, I imported our NPS results into Dovetail and categorised them with the same taxonomy from our customer interviews.</p>
                            <p>From there, I was able to form a collection of teachers that had left negative comments about specific features. I contacted these teachers directly to meet for interviews and user testing to learn more about how things could be improved.</p>
                    </Section>

                    <Section
                        {...props}
                        heading="The solutions">
                            <p>There was, of course, no silver bullet to magically solve the issues in our teacher experience. Rather, the results of my meetings with teachers formed the basis of a long list of small-to-medium sized improvements. This included things like changes to the navigation, onboarding, data tables, and a range of issues with hierarchy and consistency.</p>
                            <p>Over time, we were able to regain some ground in user experience that had been lost over time, while also gaining more good-will among new and engaged customers.</p>
                    </Section>
            </Section>
        </>
    )
}

export function Stan(props) {
    return (
        <>
            <Header
                {...props}
                title="Stan"
                description="I joined as a contract product designer, then took the lead after success with a few key projects."
            />

            <Section
                {...props}
                showInPreview
                // visual={ <Image alt="Examples of the new marketing pages for Stan" src={{
                //     small: require('./images/stan/6.jpg'),
                //     large: require('./images/stan/6@2x.jpg')
                // }}/> }
                visual={ <Video backgroundIsOverlayed src={{
                    background: require('./images/stan/watch-overview-mask.png'),
                    poster: require('./images/stan/watch-overview-poster.jpg'),
                    webm: require('./images/stan/watch-overview.webm'),
                    mp4: require('./images/stan/watch-overview.mp4'),
                }}/> }
                title="Stan"
                role="Lead Product Designer"
                date="2020 --> 2021"
                blurb="I joined as a contract product designer, then took the lead after success with a few key projects."
                heading="Marketing pages">
                    <p>A redesign of 10,000+ promotional pages that lead to a boost in SEO and conversion.</p>
                    <p>When I joined Stan, a large part of traffic driven to marketing pages was from paid ads.</p>
                    <p>A plan was put in place to reduce this spend over time, so we needed an overhaul of all marketing pages with SEO in mind.</p>
                    <p>We focused on long-tail searches for things like specific shows and specific seasons rather than the homepage. </p>
                    <p>Previously, only high-profile movies and shows would get a marketing page. In the new world, all 10,000+ assets on Stan would have a publicly available marketing page.</p>
                    <p>This meant we’d need a solution flexible enough to be customised for high-profile shows, but could still be scaled automatically for lesser known titles that may have lower-quality assets and no hands-on curation.</p>
                    <p>I worked extensively with the marketing team to find a design that suited their needs across the lifecycle of an asset — from a pre-production announcement all the way to a beloved show with over 20 seasons.</p>
                    <p>I also worked with developers to ensure the designs hit all best practices for SEO, mobile, and speed.</p>
            </Section>

            <Section
                {...props}
                visual={ <Image alt="Stan Sport in the TV and mobile apps" src={{
                        small: require('./images/stan/9.jpg'),
                        large: require('./images/stan/9@2x.jpg')
                }}/> }
                heading="Stan Sport Launch">
                    <p>With a dozen platforms to launch in just a few months, I worked with PMs to craft the best possible MVP.</p>

                    <Section
                        {...props}
                        heading="I leveraged prior experience with Optus Sport">
                            <p>Prior to being at Stan, I worked on a redesign of Optus Sport. Though the focus there was on <del>soccer</del> football, many of the same principles apply when thinking about a service for on-demand sport viewing.</p>
                            <p>From the extensive research of that project, I knew the basics of what would be required for any sport platform. We’d need to think about the weekly routine of following a sport, spoilers for those watching replays and the basics of managing the stream during live viewing. All this while considering the needs of everyone from casual to hardcore levels of fandom.</p>
                            <p>But with the launch sport for Stan being Rugby rather than Football, what else was there to know that wouldn’t carry across from research I'd done in the past?</p>
                    </Section>
            </Section>

            <Image isBanner hide={props.isPreview} alt="Design activities for Stan Sport launch" src={{
                small: require('./images/stan/phase1.jpg'),
                large: require('./images/stan/phase1@2x.jpg')
            }}/>

            <Section
                {...props}
                visual={ <Image alt="Affinity mapping of insights from user interviews" src={{
                    small: require('./images/stan/2.jpg'),
                    large: require('./images/stan/2@2x.jpg')
                }}/> }
                heading="Rugby research">
                    <p>We had a great source of rugby fans from our head of sport, who wrote a regular newsletter to fans.</p>
                    <p>I spoke with a few of these, ranging from young uni students through to retirees who have been following the sport their whole life.</p>
                    <p>It became clear that we had their basic needs covered, but the conversations also highlighted the importance of getting the technology side right. Not surprisingly, high-definition picture at a high frame rate was critical to hardcore fans, followed closely by an effective commentary team.</p>
                    <p>Most of all, the most sought-after feature was to be able to watch the rugby without a Foxtel subscription.</p>

                    <Section
                        {...props}
                        heading="High-level concepts for our MVP">
                            <p>With the core requirements and fan conversations in mind, I went about creating high-level concepts for what could become an MVP.</p>
                            <p>These were designed to illustrate what the service could be, to help conversations among developers and c-level stakeholders.</p>
                            <p>With a 3-4 month window before launch, we had to be ruthless in identifying the core features quickly so that we could get cracking.</p>
                    </Section>

                    <Section
                        {...props}
                        heading="Simple signup">
                            <p>Among the features within the platform, our head of product set a challenge: can we facilitate an upgrade of existing customers in one or two clicks? Refining the signup flow to hit this goal took collaboration across developers, designers, and marketers.</p>
                    </Section>
            </Section>

            <Section
                {...props}
                visual={ <Video align="tv-left" src={{
                    background: require('./images/stan/video-tv-left.jpg'),
                    poster: require('./images/stan/holding-graphic-poster.jpg'),
                    webm: require('./images/stan/holding-graphic.webm'),
                    mp4: require('./images/stan/holding-graphic.mp4'),
                }}/> }
                heading="Live sport holding pages">
                    <p>Often, sport fans will open their app before the coverage begins. We wanted a simple way to allow them to automatically jump into viewing if they’d arrived early, rather than having to wait to press the play button when coverage begins.</p>
                    <p>This could have been a simple holding page with a static image, but the beginning of a match is a high-point of excitement and anticipation. A static image simply would not do!</p>
                    <p>I sourced and introduced stadium drone footage as a background asset as a way to emphasise the start of a match. Longer-term, we had hoped to pull through live-footage of people entering the stadium to make it more authentic.</p>

                    <Section
                        {...props}
                        heading="Content merchandising">
                            <p>Two critical points came from speaking with sport fans when trying to uncover what’s important to them while browsing a sport app.</p>
                            <p>The first is to be able to very easily discern which items are upcoming, live or replays while browsing. You don’t want to have to read every item one-by-one — it’s something you should be able to understand at a glance without thinking.</p>
                            <p>The other seems obvious, but many other services get it wrong — no spoilers! There’s nothing worse than avoiding the internet so you can watch an important replay, only to have the result spoiled by the description. Key moments in a match can also be given away with the artwork used, so I put together detailed guidelines for our content team to help when selecting match photos to be used.</p>
                    </Section>

                    <Section
                        {...props}
                        heading="I scaled the MVP to many platforms">
                            <p>From the concepts, we looked towards the launch. This meant diving into the details and creating detailed high-fidelity comps and flow diagrams to hand off to developers.</p>
                            <p>As we progressed, I worked closely with product owners and developers to refine things when we encountered issues.</p>
                    </Section>
            </Section>

            <Section
            {...props}
            visual={ <Video align="tv-right" src={{
                background: require('./images/stan/video-tv-right.jpg'),
                poster: require('./images/stan/timeline-events-poster.jpg'),
                webm: require('./images/stan/timeline-events.webm'),
                mp4: require('./images/stan/timeline-events.mp4'),
            }}/> }
            heading="Recapping a match in progress">
                <p>With Sport, our focus was on prioritising the live experience. By default, you'd jump straight to the current point in the stream when joining just like on an old-school TV. There's no fuss to get straight into the action.</p>
                <p>If you're joining late, an obvious downside to this is that you've missed all the action. You still want to watch from the current live point so that you can keep up with what's happening now, but for a lot of fans it's critical to know how you got there. When watching a traditional broadcast, your only real option is to wait until a break in play and hope for a recap. But we can do better.</p>
                <p>Here you can see a prototype I built in Protopie as a way to explore how we might allow people to catch up on what they've missed during a live broadcast. Events are added to the progress bar, so you can see what happened and when. If you spot an interesting point, you can easily snap back to that point to watch it, and then jump back to the live point.</p>
                <p>On a notoriously clunky platform like TV, it needed to be super simple to navigate with a basic remote control.</p>
            </Section>

            <Section
                {...props}
                visual={ <Image alt="Examples of the tiles used for Stan Sport" src={{
                    small: require('./images/stan/4.jpg'),
                    large: require('./images/stan/4@2x.jpg')
                }}/> }
                heading="UI Design + Automation">
                    <p>I created an automated workflow with Figma to generate 10,000+ assets for the Stan Sport launch.</p>
                    <p>When launching Stan Sport, we had a few things come together to cause a very practical problem to resolve for launch:</p>
                    <ul>
                        <li><p>Every show, movie and sports match on Stan required about a dozen images of various dimensions to suit all of the different platforms supported.</p></li>
                        <li><p>With the launch of Stan Sport, we would offer replays for international and club matches dating back to the 1990s.</p></li>
                        <li><p>A person has to manually upload imagery for each match added to Stan.</p></li>
                    </ul>
                    <p>Do the maths on that, and you end up with a lot of manual drudgery for a lot of people to chew through to hit the launch date. It’s over 10,000 separate images that needed to be manually cropped and exported with a meaningful name, for someone to then manually upload. We didn’t have the budget to hire a team of people to manage this, and nor the time for a single person to tackle it.</p>

                    <Section
                        {...props}
                        heading="UI automation to save the day">
                            <p>I created a ridiculously elaborate system of automation to solve this. If I’m honest, I still don’t know if it’s a work of genius or madness at this point.</p>
                            <p>Driven by a spreadsheet, our sport team were able to enter details of every match being added to the platform.</p>
                            <p>Images were automatically matched from team names to ones sourced separately.</p>
                            <p>This was then imported into Figma, which then — via a plugin — renamed a series of pre-made frames and imported all images into the right place.</p>
                            <p>The designer was then able to make any final layout tweaks, and then export.</p>
                            <p>Because of a Figma limitation, these frames were exported with generic names, like “Frame 1”, “Frame 2”, etc. Given a human was going to upload these to specific places, this created too much risk of mistakes to be made.</p>
                            <p>To solve this, I wrote a script from the original spreadsheet to batch-rename all files exported. This organised images into a meaningful folder structure with human-readable filenames.</p>
                            <p>Madness. But madness that saved hundreds of hours of work. </p>
                    </Section>
            </Section>

            <Image isBanner hide={props.isPreview} alt="Tennis refinements for Stan Sport" src={{
                small: require('./images/stan/phase2.jpg'),
                large: require('./images/stan/phase2@2x.jpg')
            }}/>

            <Section
                {...props}
                visual={ <Video align="tv-left" src={{
                    background: require('./images/stan/video-tv-left.jpg'),
                    poster: require('./images/stan/live-switcher-poster.jpg'),
                    webm: require('./images/stan/live-switcher.webm'),
                    mp4: require('./images/stan/live-switcher.mp4'),
                }}/> }
                heading="Watching multiple matches">
                    <p>After a successful launch, we had to quickly turn to the next sport to be added to Stan Sport: Tennis.</p>
                    <p>The timing was great — the Australian Open had just started, so it was a perfect opportunity to conduct some research during a real tennis tournament.</p>
                    <p>I spoke with tennis fans, both casual and hardcore, to understand our gaps between the launch of Stan Sport for rugby, and our upcoming coverage of the French Open.</p>
                    <p>There was one main difference that came up between tennis and rugby: with our rugby coverage, there was only ever one match live at a time. This meant that our navigation that had been designed for movies and TV shows largely worked without needing to be changed.</p>

                    <Section
                        {...props}
                        heading="Navigating tennis">
                            <p>Tennis tournaments can be over 10 courts of play, all happening at once. This affected us in two ways.</p>
                            <p>The first was relatively straightforward: what’s the best way to organise all of this content to make it easy to find the match you’re looking for.</p>
                            <p>The second was the way tennis fans liked to watch. With so many matches happening at once, tennis fans will often juggle multiple channels at the same time. Typically, there will be one main match holding most of their focus, with 1-2 others they may periodically switch over to. This could be due to big players having matches at the same time, or just wanting to watch all of the Australian players.</p>
                            <p>Because our apps were designed to watch one movie or show at a time, switching matches like this would be incredibly clunky.</p>
                    </Section>

                    <Section
                        {...props}
                        heading="The fix">
                            <p>Although it offers the best live sport viewing experience, the clunkiness of remote controls meant our TV apps were going to offer the worst experience for switching between multiple matches. With limited time to launch, TV became the lead platform to solve this issue.</p>
                            <p>Simplicity is key when designing for TV — by placing the switcher below the main timeline, it could be summoned with a single button press. It also meant that changes to the rest of the player UI would be kept to a minimum, helping us hit our timeline for launch.</p>
                            <p>Another key issue to solve was how to make users aware it existed. I experimented with three versions during testing, where I played with different options for the timing of the hinting. The most successful was to show the hint just after the video begins playing.</p>
                            <p>This video is a capture of prototype I made for user testing using Protopie. Did I fool you with the fake loading states? It looks pretty real! I even tested it with a bluetooth TV remote.</p>
                            <p>This testing gave us confidence that we had the right solution for the switcher, and that it was worth investing in. This prototype also gave us a path forward for future refinements — set times and upcoming matches woudln't be in scope at launch, but we anticipated they would be highly desirable.</p>
                    </Section>
            </Section>

            <Section
                {...props}
                visual={ <Video align="tv-left" src={{
                    background: require('./images/stan/video-tv-left.jpg'),
                    poster: require('./images/stan/content-merchandising-poster.jpg'),
                    webm: require('./images/stan/content-merchandising.webm'),
                    mp4: require('./images/stan/content-merchandising.mp4'),
                }}/> }
                heading="Content Merchandising">
                    <p>SVOD services can easily become a wall of rectangles. I prototyped better ways to showcase content.</p>
                    <p>We had a goal to make Stan more than just another digital video store.</p>
                    <p>Outside of just making our service more engaging and fun to use, our content team had no practical way to give weight to different kinds of content. Beyond a hero carousel at the top of our apps, the rest appeared exactly the same regardless of its value.</p>
                    <p>I worked with our content and marketing teams to create concepts to elevate our content, without turning it into a carnival of things grabbing for your attention.</p>
                    <p>This video is a capture from a prototype I made with Protopie at medium-fidelity. We used it to evaluate new ideas quickly both within the team and with user testing participants.</p>

                    <Section
                        {...props}
                        heading="Continue watching">
                            <p>Using a landscape aspect ratio to break the feed of portrait tiles, these also make use of episodic imagery. This means gives you two things: fresh imagery if you’re watching a show with lots of episodes over a long period of time, and some context to where you’re up to with up-to-date characters appearing as you progress.</p>
                    </Section>

                    <Section
                        {...props}
                        heading="Inline trailers">
                            <p>Fairly straightforward, this allowed us to have a secondary row of trailers lower down the page. This was great for promoting upcoming content that hadn’t launched yet.</p>
                    </Section>

                    <Section
                        {...props}
                        heading="Vertical videos">
                            <p>This served two purposes. One was to simply show larger-format tiles to give higher prominence to the feed, and another was to be able to have dynamic video content available without sacrificing the number of items visible in the row.</p>
                    </Section>

                    <Section
                        {...props}
                        heading="Collections">
                            <p>From James Bond to The Hunger Games, so much of Stan's content exists as part of a larger whole. The collection feed allowed us to keep it all in once place, and highlight high-value collections to tempt super-fans.</p>
                    </Section>
            </Section>

            <Section
                {...props}
                visual={ <Image alt="Examples of profile pics for Stan" src={{
                    small: require('./images/stan/8.jpg'),
                    large: require('./images/stan/8@2x.jpg')
                }}/> }
                heading="Art Direction for profile pics">
                    <p>Working with illustrators, we expanded Stan’s profile pics, inspired by content on Stan.</p>
                    <p>From an initial set of simple emoji as profile pictures, we wanted to expand this into a series to celebrate content on Stan.</p>
                    <p>Rather than sourcing from a single artist in one style, we decided that a better reflection of the diversity of content on Stan would be to work with a different illustrator for each set.</p>
                    <p>The initial sets celebrated some of our most popular content: RuPaul, Yellowstone, and our kids’ content.</p>
                    <p>I wrote the briefs, gathered mood boards and gave initial art direction to illustrators. Following initial sketches and concepts, I consolidated feedback from stakeholders internally to finalise the profile pics.</p>
            </Section>
        </>
    )
}

export function Optus(props) {
    // scroll points
    // var positionsIndex = 0;
    // var positions = [0, 255, 1070, 2884, 3897, 6210, 7632, 8378, 9144, 18995, 21500, 26614, 36447, 48028, 0]
    // top = positions[positionsIndex];
    // positionsIndex++;
    // experience overview x3, personas, key screens x4, layout x2 (end on portrait tablet), motion, tiles, video player, experience overview

    const feedback = [
        {
            stars: 5,
            // content: '“Since the latest update with the new features, I\'ve loved using Optus Sport again. Skipping ahead or rewinding is much easier than before. The layout is lovely and everything is much easier to find. The ability to choose which highlights package I want to see for a game is much smoother than before.”',
            content: '“Since the latest update with the new features, I\'ve loved using Optus Sport again ... The layout is lovely and everything is much easier to find.”',
            author: 'Android review',
        },
        // {
        //     stars: 5,
        //     content: '“I love the app, great UI, easy to navigate, nice & colourful.”',
        //     author: 'Christopher Oliver',
        // },
        // {
        //     stars: 5,
        //     // content: '“Absolutely amazing app, cant fault it, I\'ve used Sky sports app in UK, and was horrendous, so wasnt expecting alot from optus sports, but was pleasantly surprised, the content and User interface is fantastic, with 3 min highlights and 24 min extended highlights, and easy navigation around the app”',
        //     content: '“Absolutely amazing app, cant fault it ... the content and User interface is fantastic ... easy navigation around the app”',
        //     author: 'Android review',
        // },
        // {
        //     stars: 5,
        //     content: '“This is the best soccer platform ever 🙌 Thank you, Optus.”',
        //     author: 'Android review',
        // },
        {
            stars: 5,
            content: '“Love it. The recent updates are great too. Much easier to use and to find highlights and mini match. The spoiler protection is a great addition too.”',
            author: 'Android review',
        },
    ]
    return (
        <>
            <Header
                {...props}
                title="Optus Sport"
                description="I lead the planning, strategy, and design for the redesign of Optus Sport"
                links={[
                    { label: 'iOS',     url: 'https://apps.apple.com/au/app/optus-sport/id1113368382' },
                    { label: 'Android', url: 'https://play.google.com/store/apps/details?id=au.com.optus.sport' },
                    { label: 'Web',     url: 'https://sport.optus.com.au' }
                ]}
            />

            <Section
                {...props}
                showInPreview
                // visual={ <Image alt="A sample of key screens from the redesigned Optus Sport app" src={{
                //         small: require('./images/optus/1.jpg'),
                //         large: require('./images/optus/1@2x.jpg')
                // }}/> }
                visual={ <Video backgroundIsOverlayed src={{
                    background: require('./images/optus/hero-mask.png'),
                    poster: require('./images/optus/hero-poster.jpg'),
                    webm: require('./images/optus/hero.webm'),
                    mp4: require('./images/optus/hero.mp4'),
                }}/> }
                title="Optus Sport"
                role="Product Design Lead (Contract)"
                date="2019"
                blurb="I lead the planning, strategy, and design for the redesign of Optus Sport"
                heading="Strategy"
                feedback={feedback}>
                    <p>To kick off the project, I facilitated stakeholder workshops to identify a strategic direction for Optus Sport moving forward.</p>
                    <p>I facilitated collaborative workshops with stakeholders to identify the product vision for the future. This involved getting everyone in a room together, and discussing a vision for what Optus Sport should and shouldn’t be.</p>
                    <p>We explored the needs of current and potential future customers, the strengths of the service, and where we wanted it to be in an ideal world.</p>
                    <p>This all came together as draft product vision statement. Something crafted from stakeholders representing all parts of the business, from which all teams could align and rally behind.</p>
                    <p>After taking this draft away and refining, I presented the final product vision back to the group to align with senior stakeholders and internal teams.</p>
                    <p>From here, we had our direction and alignment and could get cracking on some intense research and exploration of new concepts.</p>
            </Section>

            <Image isBanner hide={props.isPreview} alt="Phase 1: Establishing our direction & design system" src={{
                small: require('./images/optus/phase1.jpg'),
                large: require('./images/optus/phase1@2x.jpg')
            }}/>

            <Section
                {...props}
                visual={ <Image alt="A workshop to define the product vision of Optus Sport" src={{
                    small: require('./images/optus/2.jpg'),
                    large: require('./images/optus/2@2x.jpg')
                }}/> }
                heading="Research & validation">
                    <p>With our product vision in place, I then conducted interviews with existing customers to identify pain points and opportunities. What gaps do have in serving their needs right now, and in achieving our vision for the future?</p>
                    <p>With most people using Optus Sport to watch the English Premier League, a few patterns emerged when it came to the routines of users watching matches out of their own timezone. This mapped closely to their level of fandom, with hardcore fans likely to wake up in the middle of the night to watch their team live, and more casual fans being happy with replays.</p>
                    <p>It was critical to understand the ups and downs of this weekly routine in order to serve each of these types of viewers.</p>
                    <p>After collecting insights from these interviews, we (myself and one other designed) created concepts to address shortcomings in the service and performed user testing to evaluate them.</p>
                    <p>We also created three separate visual directions for the product, ranging from a radical new look to a small refinement to the current product, and included some preference testing among our participants as a temperature check.</p>
                    <p>This rapid concepting helped to validate or rule out new ideas, with results presented back to stakeholders to align on the product vision moving forward.</p>
            </Section>

            <Section
                {...props}
                visual={ <Video align="laptop" src={{
                    background: require('./images/optus/video-laptop.jpg'),
                    poster: require('./images/optus/optus-toolkit-poster.jpg'),
                    webm: require('./images/optus/optus-toolkit.webm'),
                    mp4: require('./images/optus/optus-toolkit.mp4'),
                }}/> }
                heading="The experience design toolkit">
                    <p>It takes many teams all pulling in the same direction to create a product at the scale of Optus Sport.</p>
                    <p>It can become a real challenge to make sure everything remained consistent with so many teams working independently.</p>
                    <p>With the experience design toolkit, we set out to deliver our high-level vision across the whole service. Any team could use the toolkit to interpret the overarching vision, regardless of their platform of focus.</p>
                    <p>We made sure that key screens and features were highlighted, demonstrating how they connected back to the core vision of what Optus Sport should be.</p>
                    <p>This gave concrete examples of how we expected the vision to be implemented, while still leaving enough wiggle room for teams to embellish on their own.</p>
                    <p>I lead the creation of the toolkit, which included using a pre-made framework to help with the initial structure.</p>
                    <p>As we continued with the project in our detailed design phase, the toolkit was updated and refined as a living document.</p>
            </Section>
            
            <Image isBanner hide={props.isPreview} alt="Phase 2: Detailed design & expanding the design system" src={{
                small: require('./images/optus/phase2.jpg'),
                large: require('./images/optus/phase2@2x.jpg')
            }}/>
            
            <Image isBanner hide={props.isPreview} alt="A sample of key screens from the redesigned Optus Sport app" src={{
                small: require('./images/optus/banner.jpg'),
                large: require('./images/optus/banner@2x.jpg')
            }}/>

            <Section
                {...props}
                visual={ <Image alt="A sample of the UI Kit delivered for Optus Sport" src={{
                        small: require('./images/optus/3.jpg'),
                        large: require('./images/optus/3@2x.jpg')
                }}/> }
                heading="Detailed design">
                    <p>The detailed design phases of the project involved breaking a fixed period of time into 2-week sprints. To kick this off, I worked with our primary stakeholder at Optus to make a backlog of features for these sprints.</p>
                    <p>This gave us a solid plan to move forward with, along with the flexibility to change direction rapidly if we uncovered new areas to explore.</p>
                    <p>In each sprint, we spent the first week creating high-fidelity screens and prototypes for a handful of features. We tested these with about 6 people in the second week, and then refined based on the insights gathered. On the last day, we presented the refined UX and UI back to stakeholders, and decided together if we’d move on to the next feature or make further refinements.</p>
                    <p>Mobile was our lead platform as we worked through the detailed design phase, with designs for tablet, web and TV being an evolution of those.</p>
                    <p>The final deliverable of the project was an online toolkit to establish a baseline experience for all team members, along with high-fidelity screens for the whole app across mobile, web, and TV.</p>
            </Section>
        </>
    )
}

export function Woolies(props) {
    return (
        <>
            <Header
                {...props}
                title="WooliesX"
                description="I joined the mobile app team to expand the new instant delivery service, then moved to lead UX on the Rewards app."
            />

            <Section
                {...props}
                showInPreview
                // visual={ <Image alt="A selection of screens from the Rewards iOS app" src={{
                //         small: require('./images/woolies/1.jpg'),
                //         large: require('./images/woolies/1@2x.jpg')
                // }}/> }
                visual={ <Video align="phone" src={{
                    background: require('./images/woolies/iphone-x.png'),
                    poster: require('./images/woolies/rewards-discover-poster.jpg'),
                    webm: require('./images/woolies/rewards-discover.webm'),
                    mp4: require('./images/woolies/rewards-discover.mp4'),
                }}/> }
                title="WooliesX"
                role="Senior UX/UI Designer"
                date="2019 --> 2020"
                blurb="I joined the mobile app team to expand the new instant delivery service, then moved to lead UX on the Rewards app."
                heading="Rewards App UX">
                    <p>I refined & tested Rewards to fit in the Woolies app ecosystem, using existing research on a concept app.</p>
                    <p>Prior to joining the project, a rough design and experience had been created by the Woolies Rewards team. With existing infrastructure and product teams in place, it was decided that he wider Woolies app team would take over to make use of existing technology as much as possible for the production version.</p>
                    <p>I came on board to take the existing experience and see how it could be polished and take advantage of existing UX/UI paradigms of the existing Woolworths app.</p>
                    <p>I created flow diagrams to map out the experience and agree on scope with our product owner. Once we’d decided on scope, I worked on the finished UI for half the app, splitting the work with another designer.</p>
                    <p>Here you can see a slice of the Protopie prototype I made for testing, the Discover tab, which is a map to find nearby Rewards partners. It felt realistic enough to fool one of our developers, who asked: “How did you integrate the location data into a prototype?!”</p>
            </Section>

            <Section
                {...props}
                visual={ <Video align="phone" src={{
                    background: require('./images/woolies/iphone-x.png'),
                    poster: require('./images/woolies/rewards-login-poster.jpg'),
                    webm: require('./images/woolies/rewards-login.webm'),
                    mp4: require('./images/woolies/rewards-login.mp4'),
                }}/> }
                heading="Testing the new Woolies login">
                    <p>Part of the Rewards project was to integrate a new single-sign-on platform, to be used by all Woolies services moving forward.</p>
                    <p>This would be web-based, so had some technical constraints that would impact the overall user experience. It would need to be served via a web-view of some kind, rather than being built natively.</p>
                    <p>Loading times and overall responsiveness were a significant concern, so we felt it was important to do a round of user testing to help gauge the overall impact.</p>
                    <p>Since the objective of the testing was to discover whether these points of friction would cause issues for the rest of the app experience, a simple screen-to-screen prototype wouldn’t be viable.</p>
                    <p>To simulate the friction points we were evaluating, I opted to create our prototype in Protopie.</p>
                    <p>With everything from loading delays and email validation built-in, this prototype managed to fool our testing participants into thinking it was real. When held in a real device, it even triggered the correct vibration for the SMS notification!</p>
            </Section>

            <Section
                {...props}
                visual={ <Image alt="Updated UI for Scan&Go when integrated into the Woolies app" src={{
                    small: require('./images/woolies/2.jpg'),
                    large: require('./images/woolies/2@2x.jpg')
                }}/> }
                heading="Scan&Go Refresh">
                    <p>I gave the UI a refresh, prototyped it in Protopie, and researched how to integrate it into the Woolies app.</p>
                    <p>Scan&Go originally existed as an experimental app that lived outside the main Woolworths app.</p>
                    <p>After the success of the Rewards project, I was asked to explore how the Scan&Go app could be integrated into the main Woolworths experience.</p>
                    <p>The main challenges of this were how customers would activate the feature within an already complex and feature-rich app.</p>
                    <p>While incorporating Scan&Go into the main Woolies app, I also explored how its UI could be modernised and updated to be cohesive within the wider experience.</p>
                    <p>I created refined UI, along with a high fidelity prototype in Protopie to facilitate user testing of the concept and UI.</p>
                    <p>Because of various technical constraints, there would be several long loading states throughout the experience. I made sure to build these into the prototype in way that could be easily customised to simulate slow network speeds.</p>
            </Section>

            <Section
                {...props}
                visual={ <Image alt="Concepts for real-time delivery tracking in the Woolies app" src={{
                    small: require('./images/woolies/3.jpg'),
                    large: require('./images/woolies/3@2x.jpg')
                }}/> }
                heading="Delivery Now">
                    <p>Bringing the Uber Eats-like grocery service to the app with research and iterative prototyping.</p>
                    <p>Delivery Now was first created only for the web experience for Woolworths.</p>
                    <p>I was tasked with integrating it into the Woolworths app as another option for delivery.</p>
                    <p>After creating a few initial concepts, I whipped up a quick prototype with InVision and tested with a handful of customers to validate.</p>
            </Section>
        </>
    )
}

export function Rest(props) {
    return (
        <>
            <Header
                {...props}
                title="Rest"
                description="I lead the UI on a team of three designers to concept, test and deliver a fresh experience for Rest’s super portal."
            />

            <Section
                {...props}
                showInPreview
                visual={ <Image alt="A sampling of screens from the redesigned Rest member portal" src={{
                        small: require('./images/rest/1.jpg'),
                        large: require('./images/rest/1@2x.jpg')
                }}/> }
                title="Rest"
                role="Senior Designer (Contract)"
                date="2018"
                blurb="I lead the UI on a team of three designers to concept, test and deliver a fresh experience for Rest’s super portal."
                heading="The brief">
                    <p>Rest needed a refined member portal for its young new members without alienating the oldies.</p>
                    <p>This project was with Massive Interactive, an agency that had a history in designing video-on-demand services.</p>
                    <p>This initially seemed like an odd coupling — a financial service designed by a team more familiar with entertainment apps? But this was all part of the plan!</p>
                    <p>For Rest, this lack of a background in financial services was seen as a strength. They wanted a service that threw let go of legacy ideas around financial services, and gave their customers something fresh and engaging.</p>
                    <p>They wanted something different from all of their boring competitors — a great brief to receive!</p>
            </Section>

            <Section
                {...props}
                visual={ <Image alt="A photo from one of our user testing sessions to evaluate early concepts" src={{
                    small: require('./images/rest/2.jpg'),
                    large: require('./images/rest/2@2x.jpg')
                }}/> }
                heading="Super is for life">
                    <p>To understand the needs of their wide range of users, we (myself and the UX lead) facilitated interviews with people aged 18-70.</p>
                    <p>The types of insights gained from this were fascinating. Outside of the obvious stereotypes related to older generations being less capable with technology, we also tested with people who lived with things like mobility issues that affected their ability to use digital services. For example, one person needed to use two hands to scroll their mouse due to reduced dexterity — something most of us would take for granted.</p>
                    <p>As part of the brief for out-of-the-box thinking, we created two main concepts: one that was a more traditional dashboard inline with Rest’s competitors, and another that was much more fun and exciting.</p>
                    <p>We wanted to make sure that what was flashy and exciting was still going to be functional. We’d be creating something for managing peoples’ superannuation, after all!</p>
                    <p>After a first round of testing, we found what we had expected: the “boring” concept was more functional, but the sentiment around our “fun” concept was overwhelmingly positive. </p>
                    <p>Now the challenge was set. With two rounds of iteration and testing, we were able to update our “fun” concept to match the usability of the “boring” one. We had achieved the best of both worlds. This is what some of our testing participants had to say:</p>
                    <p>“I want to use this super. It’s so simple”</p>
                    <p>“Oh, that’s easy!”</p>
                    <p>“This is what I want!”</p>
            </Section>

            <Section
                {...props}
                visual={ <Image alt="A variety of components from the UI Kit I put together" src={{
                        small: require('./images/rest/3.jpg'),
                        large: require('./images/rest/3@2x.jpg')
                }}/> }
                heading="UI design lead">
                    <p>With our concept validated and stakeholders on board, I took the lead on creating a component library and UI system ready for developers.</p>
                    <p>Along with the new member portal, Rest had also recently undergone a rebrand. As the UI lead, I was responsible for ensuring our work aligned with this new look. This involved taking a fresh branding document — intended for print and offline applications — and translate that into interactive components that still feel part of that family.</p>
                    <p>This was then demoed back to exec-level stakeholders to ensure we’d captured the vision of their rebrand while adding enhancements for the digital world.</p>
                    <p>I delivered a design system built from the ground-up, so developers could easily nail the details.</p>
                    <p>This included high-fidelity screens across the entire new portal, and robust components that were flexible enough to be used by any new designers that joined the project in the future.</p>
                    <p>This was all put together in Sketch</p>
            </Section>
        </>
    )
}

export function Burst(props) {
    const feedback = [
        {
            title: 'TechCrunch',
            content: '“Burst breaks you out of your filter bubble on Reddit.”',
            author: 'Sarah Perez',
            authorLink: 'https://techcrunch.com/2018/04/04/burst-breaks-you-out-of-your-filter-bubble-on-reddit/',
        },
        {
            title: 'MacStories',
            content: '“A beautiful app with a design that feels fresh and modern.”',
            author: 'Ryan Christoffel',
            authorLink: 'https://www.macstories.net/reviews/burst-a-reddit-client-to-pop-your-filter-bubble/',
        },
        {
            title: 'MobileSyrup',
            content: 'App of the week. “A very slick Reddit client.”',
            author: 'Brad Bennett',
            authorLink: 'https://mobilesyrup.com/2018/04/07/reddit-client-burst-filter-bubble/',
        },
        {
            stars: 5,
            // title: 'The best',
            content: '“It’s like Apple decides to make a reddit app”',
            author: 'PINGU543',
        },
        {
            stars: 5,
            // title: 'Fantastic',
            content: '“Best reddit reader!! I’m in love with this app.”',
            author: 'Ana Luiza Alves',
        },
        {
            stars: 5,
            // title: 'Fantastic',
            content: '“Super unique ... unlike anything I\'ve used before.”',
            author: 'liamdvsn',
        },
        {
            content: '“This is very inventive. Really.”',
            author: 'deftonechromosome',
            authorLink: 'https://www.reddit.com/r/reactnative/comments/79wvbt/ive_made_a_react_native_reddit_client_with_a/dp5rmas/'
        },
        {
            content: '“Wow that\'s really cool”',
            author: 'stinkyhippy',
            authorLink: 'https://www.reddit.com/r/reactnative/comments/79wvbt/ive_made_a_react_native_reddit_client_with_a/dp5tmnc/'
        },
        {
            content: '“This is brilliant.”',
            author: 'brokenbyclouds',
            authorLink: 'https://www.reddit.com/r/reactnative/comments/79wvbt/ive_made_a_react_native_reddit_client_with_a/dp6ev44/'
        },
    ];

    return (
        <>
            <Header
                {...props}
                title="Burst for reddit"
                description="I designed, built, and launched this iOS app, which uses a novel UX to solve filter bubbles on reddit."
                links={[ {label: 'iOS', url: 'https://burst.alexduckmanton.com'} ]}
            />
            
            <Section
                {...props}
                showInPreview
                // visual={ <Image src={{
                //         small: require('./images/burst/1.jpg'),
                //         large: require('./images/burst/1@2x.jpg')
                // }}/> }
                visual={ <Video align="phone" src={{
                    background: require('./images/burst/iphone-x.png'),
                    poster: require('./images/burst/burst-overview-poster.jpg'),
                    webm: require('./images/burst/burst-overview.webm'),
                    mp4: require('./images/burst/burst-overview.mp4'),
                }}/> }
                title="Burst for reddit"
                role="Side Project (Sole Contributor)"
                date="2018"
                blurb="I designed, built, and launched this iOS app, which uses a novel UX to solve filter bubbles on reddit."
                heading="Solving Filter Bubbles"
                feedback={feedback.slice(0, 2)}>
                    <p>Fake news and echo chambers. How do you burst your filter bubble if you don’t know you’re in one?</p>
                    <p>With reddit, anyone can create a community and users subscribe to the communities they’re interested in. This works great for hobbyist discussions, but for anything news-related it creates filter bubbles almost by design. Reading conflicting opinions is hard, so most people will choose to avoid such discussion.</p>
                    <p>So can we solve this problem? Burst aims to do this by automatically merging multiple discussions into one — seamlessly bringing outside voices into otherwise polarised conversations.</p>
            </Section>

            <Section
                {...props}
                visual={ <Image alt="A diagram visualising how posts across subreddits are merged into one" src={{
                        small: require('./images/burst/2.jpg'),
                        large: require('./images/burst/2@2x.jpg')
                }}/> }
                heading="Unique Reddit UX">
                    <p>Burst merges post discussions from all subreddits to give a combined summary of all perspectives.</p>
                    <p>If a news article about an election result is posted to the politics community, Burst also finds discussions in conservative, liberal, environmental and even satire communities.</p>
                    <p>This approach to UX on reddit is unique to Burst. Most other apps are simple a vertical feed of links. Given that, this was also an opportunity to re-think what it means to be a reddit client.</p>
                    <p>As a pet-project, I really only had one stakeholder to satisfy — myself! I stripped out all UI clutter that has built up to support the wide range of users to leave a UX that is highly opinionated. Sorry, everyone else.</p>
                    <p>Plenty of reddit features were deliberately removed in favour of an experience that is simple and clutter-free. I’m more of a lurker than a contributor to reddit, so even core features like up and downvotes were taken away from the primary interface.</p>
                    <p>Another focus of the project was to incorporate a gestural UI with smooth animations.</p>
            </Section>

            <Section
                {...props}
                // visual={ <Image src={{
                //         small: require('./images/burst/3.jpg'),
                //         large: require('./images/burst/3@2x.jpg')
                // }}/> }
                visual={ <Video align="phone" src={{
                    background: require('./images/burst/iphone-x.png'),
                    poster: require('./images/burst/burst-gestures-poster.jpg'),
                    webm: require('./images/burst/burst-gestures.webm'),
                    mp4: require('./images/burst/burst-gestures.mp4'),
                }}/> }
                heading="Fluid gestures">
                    <p>Burst uses a novel UX where you navigate with gestures that are tied together with seamless transitions.</p>
                    <p>This new paradigm of swiping between comments calls for animations and transitions to aid users’ learning and understanding of the UI. From a list of posts, how can we signal to users that each can be swiped over without resorting to a verbose label?</p>
                    <p>To address this, each post’s first comment appears as a hint slightly off-screen. When swiped, the comment transitions to its full height and position. In practice the transition is invisible, but this simplicity is driven by a complex system.</p>
                    <p>Built in React Native, out-of-the-box animations are typically performed in Javascript — typically very sluggish for anything non-trivial. Burst makes use of the native driver with complex masking to achieve a performant and fully interactive transition.</p>
            </Section>

            <Section
                {...props}
                visual={ <Image alt="A diagram visualising how data is requested dynamically as users navigate the app" src={{
                        small: require('./images/burst/4.jpg'),
                        large: require('./images/burst/4@2x.jpg')
                }}/> }
                heading="Data on-demand">
                    <p>Slow network requests could put a stop to this gestural UX, but a few smart approaches to requesting data save the day.</p>
                    <p>The biggest obstacle for Burst’s approach to discussions is that network requests are slow. Burst uses some novel approaches to requesting data from the reddit API.</p>
                    <p>Typically, clients request data from a server one-by-one at the user’s request. Burst anticipates user behaviour and makes these requests ahead of time. As the user scrolls through their feed, comments are already being fetched behind the scenes. That means that by the time a user chooses a story to discuss, comments from the original post are already available.</p>
                    <p>As soon as a user enters a posts’s comments, Burst kicks into high gear. At this point, it’s looking for duplicate posts, fetching relevant discussions and merging it all together. All this takes place while the user is reading the first batch of comments already available to them. By the time they’re ready to move to the next set, Burst is already done processing it all for them.</p>
                    <p>None of this complex, behind-the-scenes logic is exposed to users. To them, Burst just feels faster.</p>
            </Section>

            <Section
                {...props}
                visual={ <Image alt="A sampling of screens from Burst" src={{
                        small: require('./images/burst/5.jpg'),
                        large: require('./images/burst/5@2x.jpg')
                }}/> }
                heading="In TechCrunch & more">
                    <p>At launch, Burst was featured on a number of tech blogs, and hit a #41 globally in news on the App Store.</p>
                    <Feedback items={feedback} />
            </Section>
        </>
    )
}

export function Beetroot(props) {
    const feedback = [
        {
            stars: 5,
            title: 'Cool app supporting community radio',
            content: '“App looks great, is easy to use, works reliably, and promotes awesome radio.”',
            author: 'Amperzand',
        },
        {
            stars: 5,
            title: 'Great app',
            content: '“Fantastic, user friendly app that provides handy access to the best of Australian community radio.”',
            author: 'Urban homesteader',
        },
        {
            stars: 5,
            title: 'Brilliant radio streaming app',
            content: '“Best app for streaming the radio channels worth streaming in Australia. Clean and well laid out.”',
            author: 'Pre ay awesum',
        },
        {
            stars: 5,
            title: 'Grouse app',
            content: '“Fantastic app, simple, easy, all my favourite radio stations GROUSE!!!”',
            author: 'Piffin Yonnies',
        },
        {
            stars: 5,
            title: 'Such a top app!',
            content: '“This is the best streaming radio app going around.”',
            author: 'Mr Molloy',
        },
        {
            stars: 5,
            title: 'Fantastic!!!',
            content: '“This app is a real winner. Used to be a real fiddle getting RRR to stream on a mobile. This does it in seconds.”',
            author: 'GusC123',
        },
        {
            stars: 5,
            title: 'Genius radio app for Aus expats',
            content: '“Works seamlessly with bonus features such as the discover option. Thanks so much!!!”',
            author: 'Crawfbob',
        },
        {
            stars: 5,
            title: 'Amazing Community Radio Hub',
            content: '“Clean, simple interface, perfect streaming. Fantastic app.”',
            author: 'LineNoise',
        }
    ];

    return (
        <>
            <Header
                {...props}
                title="Beetroot"
                description="I designed, built and deployed an app across iOS, Android, web that offers a clean, modern experience for Australian community radio."
                links={[
                    { label: 'Android', url: 'https://play.google.com/store/apps/details?id=com.beetrootios' },
                    { label: 'iOS', url: 'https://itunes.apple.com/au/app/beetroot-radio/id1016159001?mt=8' },
                    { label: 'Web', url: 'http://beetroot.fm' },
                ]}
            />

            <Section
                {...props}
                showInPreview
                // visual={ <Image src={{
                //         small: require('./images/beetroot/1.jpg'),
                //         large: require('./images/beetroot/1@2x.jpg')
                // }}/> }
                visual={ <Video align="phone" src={{
                    background: require('./images/beetroot/iphone-x.png'),
                    poster: require('./images/beetroot/beetroot-overview-poster.jpg'),
                    webm: require('./images/beetroot/beetroot-overview.webm'),
                    mp4: require('./images/beetroot/beetroot-overview.mp4'),
                }}/> }
                title="Beetroot"
                role="Side Project (Sole Contributor)"
                date="2015 --> Present"
                blurb="I designed, built and deployed an app across iOS, Android, web that offers a clean, modern experience for Australian community radio."
                heading="Community Radio Apps"
                feedback={feedback.slice(0, 2)}>
                    <p>Beetroot delivers a clean, modern client for underserved Australian community radio stations.</p>
                    <p>Built from the ground-up with community radio in mind, it’s an OTT service that goes above and beyond the expectations of listeners by providing an enhanced listening experience with simple hooks to help stations get by.</p>
                    <p>As a pet project, it’s also given me a chance to experiment with React Native. This allowed me to use my existing Javascript skills to build something that felt more native and responsive than a mere web app. </p>
            </Section>

            <Section
                {...props}
                visual={ <Image alt="A collection of screens showing how data enhances the experience in Beetroot" src={{
                        small: require('./images/beetroot/2.jpg'),
                        large: require('./images/beetroot/2@2x.jpg')
                }}/> }
                heading="Enhanced by data">
                    <p>Beetroot finds song data across multiple services to link you to things like Spotify and gig tickets.</p>

                    <p><strong>Supporting artists</strong></p>
                    <p>A typical issue with radio is that it’s hard to know what song is playing at a given time. Radio announcers aren’t always perfect at back-announcing, and even if they are it’s easy to miss.</p>
                    <p>Many apps solve this by providing song information as you listen. Beetroot goes further by allowing you to take action on each track.</p>
                    <p>With every track, you’re given links to third-party services like Spotify and Apple Music, so you can save them for later.</p>
                    <p>In addition, Beetroot also searches for upcoming gigs for all artists being played, so it’s easy to see and support artists you love.</p>

                    <p><strong>Supporting stations</strong></p>
                    <p>Beetroot also does the same for stations. Community support can mean the difference between life and death for a local station.</p>
                    <p>In service of this, listeners on Beetroot are given quick access to donation and merch pages to give back easily.</p>
            </Section>

            <Section
                {...props}
                visual={ <Image alt="A photo of me presenting Beetroot for the Walkley grant for Innovation in Journalism" src={{
                        small: require('./images/beetroot/3.jpg'),
                        large: require('./images/beetroot/3@2x.jpg')
                }}/> }
                heading="Walkley Long-Listed">
                    <p>Selected for the long list for the Walkley Grants for Innovation in Journalism.</p>
                    <p>The Walkley Foundation benchmarks the industry standard for excellence and best practice journalism. They work independently with all media organisations to enable Australian journalists to tell the stories of our nation and strengthen our democracy. They aim to engage both the media and the public to value, celebrate, and support great Australian journalism.</p>
                    <p>Projects long-listed for the grant were focused on podcasting, community radio, secure communications and automated data journalism. Winners would share the pool of $50,000 in seed funding from the Walkleys’ innovation partner Google Australia.</p>
                    <p>I was invited to a two-day workshop with twelve other finalists in order to refine ideas before pitching to judges (that’s me!)</p>
                    <p>I focused on pitching to build out Beetroot’s support features to give stations better access to community funding.</p>
            </Section>

            <Section
                {...props}
                visual={ <Image alt="A collection of screens from Beetroot" src={{
                        small: require('./images/beetroot/4.jpg'),
                        large: require('./images/beetroot/4@2x.jpg')
                }}/> }
                heading="A 5-Star Magnet">
                    <Feedback items={feedback} />
            </Section>
        </>
    )
}

export function Att(props) {
    return (
        <>
            <Header
                {...props}
                title="DirecTV"
                description="I lead the UI adaptation for this huge SVOD service, and did a lil project management to keep the team agile."
            />

            <Section
                {...props}
                showInPreview
                visual={ <Image alt="A collection of screens from the DirecTV Now app" src={{
                        small: require('./images/att/1.jpg'),
                        large: require('./images/att/1@2x.jpg')
                }}/> }
                title="DirecTV Now"
                role="Senior UI Designer (Contract)"
                date="2017 --> 2018"
                blurb="I lead the UI adaptation for this huge SVOD service, and did a lil project management to keep the team agile."
                heading="A Huge Service">
                    <p>DirecTV Now is a subscription streaming television service owned by AT&T.</p>
                    <p>The core product for the web and Apple devices was designed by their in-house team. My team was responsible for taking those core designs, and adapting them to all other platforms to be supported. This included Android, Windows, Roku, FireTV, Xbox, and Tizen.</p>
                    <p>Our goal was to take the core experience principles laid out by the internal team, and ensure that their vision was upheld while respecting native platform conventions across the board.</p>
                    <p>This project required a deep understanding of a wide variety of platforms. From the start, we aimed to find the right balance between delivering a consistent experience across devices, while respecting platform conventions so that each experience felt native and natural.</p>
            </Section>

            <Image isBanner hide={props.isPreview} alt="Fortnightly sprint activities" src={{
                small: require('./images/att/process.jpg'),
                large: require('./images/att/process@2x.jpg')
            }}/>

            <Section
                {...props}
                visual={ <Image alt="A slice of the UI Kit I worked on" src={{
                        small: require('./images/att/2.jpg'),
                        large: require('./images/att/2@2x.jpg')
                }}/> }
                heading="Even Huger UI Kits">
                    <p>I was responsible for final screens & UI components across all platforms we delivered.</p>
                    <p>Given the scale of the project – with development teams scattered across the globe – it was critical to deliver our designs with a high level of fidelity, with components fully broken down and specified in detail.</p>
                    <p>I maintained UI kits across android, windows, and several tv platforms, and kept them updated as changes to the core platform arrived every fortnight.</p>
                    <p>Given the pace of the updates and the number of platforms required to support, I established a number of workflows to ensure we could stay on top of the workload.</p>
                    <p>In addition to managing these UI kits, I also spent time research each platform and fed back any new platform updates to the team.</p>
            </Section>

            <Section
                {...props}
                visual={ <Image alt="A collection of screens from the DirecTV Now app" src={{
                        small: require('./images/att/3.jpg'),
                        large: require('./images/att/3@2x.jpg')
                }}/> }
                heading="With huge process">
                    <p>While the project was being established, I also stepped into a project management role in order to better organise the team.</p>
                    <p>I created and implemented a fortnightly sprint plan including regular planning sessions, retrospectives, and preparations for client presentations.</p>
                    <p>As the project continued, I took the lead on client presentations and managing feedback as it was received.</p>
            </Section>
        </>
    )
}

export function Fa(props) {
    const feedback = [
        // {
        //     stars: 5,
        //     title: 'A great app...just got better',
        //     content: '“It\'s a great service and the app makes it much easier to find and book my appointments.”',
        //     author: 'Jayreys',
        // },
        {
            stars: 5,
            title: 'Beta feedback',
            content: '“Improvements have been made since my last booking on-line. This works so much better - well done :)”',
            author: '1stAvailable user',
        },
        {
            stars: 5,
            title: 'Excellent App',
            content: '“The App is so responsive and user friendly even my computer illiterate partner uses it without any issues.”',
            author: 'Rhianna04',
        },
        // {
        //     stars: 5,
        //     title: 'Quick and easy',
        //     content: 'Very easy to navigate. I was able to find and book my appointment in only a couple of clicks”',
        //     author: 'Redditrodeo',
        // },
        // {
        //     stars: 5,
        //     title: 'A must have app',
        //     content: '“Fantastic app a must have for anyone who has family and needs to quickly book doctor appointments”',
        //     author: 'Muzzadawg',
        // },
        // {
        //     stars: 5,
        //     title: 'Beta feedback',
        //     content: '“Easiest appointment I\'ve made, not only in the medical field but ever!”',
        //     author: '1stAvailable user',
        // },
        // {
        //     stars: 5,
        //     title: 'Beta feedback',
        //     content: '“Easy to navigate and incredibly easy to make an appointment”',
        //     author: '1stAvailable user',
        // },
    ]
    return (
        <>
            <Header
                {...props}
                title="1stAvailable"
                description="I lead a product redesign that took centre-stage of 1stAvailable’s IPO, and launched inside a 3-month deadline."
            />

            <Section
                {...props}
                showInPreview
                visual={ <Image alt="A collection of screens from the 1stAvailable mobile web experience" src={{
                        small: require('./images/1stavailable/1.jpg'),
                        large: require('./images/1stavailable/1@2x.jpg')
                }}/> }
                title="1stAvailable"
                role="Head of Design & UX"
                date="2014 --> 2016"
                blurb="I lead a product redesign that took centre-stage of 1stAvailable’s IPO, and launched inside a 3-month deadline."
                heading="A healthcare service in need of surgery"
                feedback={feedback}>
                    <p>1stAvailable (now MyHealth1st) provides a convenient, easy to use, online healthcare search and appointment booking service. This enables patients to book their healthcare appointments online, 24 hours a day, 7 days a week from their phone, tablet or computer.</p>
                    <p>Healthcare is a funny thing. It’s not important to most people until it suddenly becomes the most important thing. Ironically, getting great outcomes for your users means they’ll probably need to use your product less often. So how do you drive engagement for a critical product that people actively avoid?</p>
                    <p>I joined to tackle an ambitious goal: redesign the service and build it in 3 months in time for an IPO.</p>
            </Section>

            <Section
                {...props}
                visual={ <Image alt="Some quick early wireframes I sketched during the concepting phase of the project" src={{
                        small: require('./images/1stavailable/2.jpg'),
                        large: require('./images/1stavailable/2@2x.jpg')
                }}/> }
                heading="Rapid iteration to find easy bookings">
                    <p>Previously, booking an appointment took six separate pages. After the redesign, I got it down to one.</p>
                    <p>This was through a process of rapid iteration, driven primarily by extremely low-fidelity wireframes sketched on paper. This allowed for a concepting phase where any idea could be explored. This included wireframes designed by me, but also allowed for all other stakeholders to get involved — from support staff to developers, all the way up to the founder of the company.</p>
                    <p>The biggest gain was from an improved search experience. Previously, users needed to complete a full page of information before being shown any practitioners in their area. This added a lot of friction to the experience.</p>
                    <p>In the redesign, gathering information about a user was deferred until after they had selected a doctor. This meant that they could browse available doctors before having to commit time to filling in a boring form.</p>
                    <p>The next win came from pre-filled selections in the search, and dynamically updating the page. This made it much easier to make single-click changes to the search, and browse different areas by simply panning the map.</p>
            </Section>

            <Section
                {...props}
                visual={ <Image alt="A collection of screens from the 1stAvailable desktop web experience" src={{
                        small: require('./images/1stavailable/3.jpg'),
                        large: require('./images/1stavailable/3@2x.jpg')
                }}/> }
                heading="Finding quality over quantity">
                    <p>Patients have better outcomes if they see the same doctor. The redesign put this front and centre.</p>
                    <p>Once patients have left the service and are with their doctor, how can we know the best way to tailor the service to their needs. The key is with their next booking.</p>
                    <p>It’s well established that patients will have better outcomes if they visit the same doctor with each subsequent appointment. The redesign of 1stAvailable took this insight front-and-centre by turning the hero banner of the site into a space for patients’ doctors and practices. More than simply promoting these doctors in a user’s search results, they sit at a higher level and make the service their own.</p>
                    <p>This new feature allowed patients to schedule an appointment with their preferred doctor in just three clicks. It accounted for over 30% of bookings soon after launch, which far exceeded expectations. </p>
            </Section>
        </>
    )
}

export function Playstation(props) {
    return (
        <>
            <Header
                {...props}
                title="PlayStation DevNet"
                description="I joined as the first designer on a decade-old project. I worked on a visual overhaul, and redesigned the navigation and search."
            />

            <Section
                {...props}
                showInPreview
                visual={ <Image alt="A collection of screens from the redesigned verion of DevNet" src={{
                        small: require('./images/playstation/1.jpg'),
                        large: require('./images/playstation/1@2x.jpg')
                }}/> }
                title="PlayStation DevNet"
                role="Senior Web Designer"
                date="2012 --> 2014"
                blurb="I joined as the first designer on a decade-old project. I worked on a visual overhaul, and redesigned the navigation and search."
                heading="Critical for game devs">
                    <p>DevNet is PlayStation's global development platform, facilitating developer and publisher support, internal communication, documentation, file consumption, and the management of several bespoke processes key to PlayStation development.</p>
                    <p>At the time of starting the project, DevNet was — and still is — critical to the creation of PlayStation games for developers and publishers across the world. It had been developed internally, and grew organically without a meaningful design effort for over a decade.</p>
            </Section>

            <Section
                {...props}
                visual={ <Image alt="Some of my sketches from some early concepts for the redesign" src={{
                        small: require('./images/playstation/2.jpg'),
                        large: require('./images/playstation/2@2x.jpg')
                }}/> }
                heading="Research to wireframes">
                    <p>A research-led project. I conducted extensive interviews to identify the lowest hanging fruit for refinement.</p>
                    <p>Usability and design were front-of-mind for the team, but what’s the best way to approach the redesign of a platform so critical to the business? Answering that question became a central part of the project as it started.</p>
                    <p>Clues to find the answer came from extensive interviews and reviews with users of all stripes. From support staff using the platform all day every day, to game developers accessing documentation and SDK updates, to publishers getting things out the door at the end of a long development cycle.</p>
                    <p>Moving forward we identified critical changes to be made. The platform’s main navigation was to be overhauled along with significant signposting updates, including breadcrumbs, quick links and colour coding. While making these structural changes, a fresh coat of paint would be applied across the board.</p>
            </Section>

            <Section
                {...props}
                visual={ <Image alt="One participant's results from the card sorting exercise" src={{
                        small: require('./images/playstation/3.jpg'),
                        large: require('./images/playstation/3@2x.jpg')
                }}/> }
                heading="Cart sorting our way to a coherent IA">
                    <p>Knowing the path forward, it was important to gather more data to inform the updated information architecture and structure of the site.</p>
                    <p>Time for some card sorting! Each area of the navigation was painstakingly documented and given to dozens of users to sort into categories. This data provided invaluable insight into how various users understood content on the platform, and was used to form the redesigned IA.</p>
                    <p>This research also revealed unexpected insights into how some users prioritised content on the platform. When being asked to name their content groups, one developer used “Docs”, “Support” and “Other shit I don’t care about”. Again, this all factored into how we promoted various content within the redesigned navigation.</p>
            </Section>

            <Section
                {...props}
                visual={ <Image alt="A collection of screens from the redesigned verion of DevNet" src={{
                        small: require('./images/playstation/4.jpg'),
                        large: require('./images/playstation/4@2x.jpg')
                }}/> }
                heading="Prototyping to success">
                    <p>Prototyping was a core practice in the redesign project and was crucial to its success. Prototypes were various levels of complexity, from sketched paper prototypes all the way to fully-functional builds polished for production.</p>
                    <p>Regardless of the complexity, the goals were the same — determine users’ understanding of the new UI; document any potential usability issues; iterate quickly. Testing with about half a dozen people turned out to be the magic number. That way the testing cycle was kept short enough to iterate on a prototype multiple times within a single week.</p>
                    <p>Depending on the scale of the insights I needed, I tapped different types of people for testing. For small bits of UI, I was able to test within the team for very rapid turnaround.</p>
                    <p>For larger pieces of work with more certainty needed, I went to outside teams within PlayStation, game studios way out of the city, and even on a trip to Japan to test with non-English speakers when validating a completely re-worked IA. Testing via a translator was a really fun experience!</p>
            </Section>

            <Section
                {...props}
                visual={ <Image alt="Some of the postcards I designed for our scrum team" src={{
                        small: require('./images/playstation/5.jpg'),
                        large: require('./images/playstation/5@2x.jpg')
                }}/> }
                heading="Scrum postcards">
                    <p>An illustrated series of postcards depicting sprint retrospective actions, used as office decoration.</p>
                    <p>As part of sprint retrospectives at PlayStation, action items are often generated to address any changes or additions to processes or workflows. This series captures a number of ongoing actions, and are used as decoration around the office.</p>
                    <p>They are printed as real postcards, and were occasionally mailed across the pond to other teams engaged in the project.</p>
                    <p>Artwork was created using a mix of Photoshop and Illustrator.</p>
            </Section>
        </>
    )
}

export function Thumbcinema(props) {
    const feedback = [
        {
            content: '“As a fellow web developer let me say, this is truly outstanding work. The dedication and attention to detail you put into this really shows, and both the idea and execution are simply brilliant.”',
            author: 'pilaf',
            authorLink: 'http://www.reddit.com/r/animation/comments/161cim/i_made_a_website_for_creating_flipbook_animations/c7rxmsp'
        },
        {
            content: '“I love this uber simple flipbook style animation site, reminds me of doing animations in the corner of textbooks”',
            author: 'James Gulliver Hancock',
            authorLink: 'https://twitter.com/gulliverhancock/status/408747164605489152'
        },
        {
            content: 'This seems like it could be really fun',
            author: 'Jessica Hische',
            authorLink: 'https://twitter.com/jessicahische/status/409758030427721729'
        },
    ];

    return (
        <>
            <Header
                {...props}
                title="Thumbcinema"
                description="I designed, developed and launched this web-based tool for creating flipbook animations."
            />

            <Section
                {...props}
                showInPreview
                visual={ <Image alt="The desktop and mobile views for Thumbcinema" src={{
                        small: require('./images/thumbcinema/1.jpg'),
                        large: require('./images/thumbcinema/1@2x.jpg')
                }}/> }
                title="Thumbcinema"
                role="Side Project (Sole Contributor)"
                date="2015"
                blurb="I designed, developed and launched this web-based tool for creating flipbook animations."
                heading="Make flipbooks easily"
                feedback={feedback.slice(0, 1)}>
                    <p>A simple, intuitive tool for creating flipbook animations without complex industry tools.</p>
                    <p>When creating this project, I was interested in making something that allowed people to be creative in new ways. I was interested in UI animation, and experiences that showed rather than told.</p>
                    <p>An early design principle was to avoid labels, alerts, and messages at all costs. If I could communicate actions and outcomes visually, I would do it.</p>
                    <p>Animation on the web was in its infancy at the time, so this was a great opportunity to learn about new technologies so that I could apply them to my day-to-day work, where I was less able to experiment.</p>
                    <p>Thumbcinema was designed in Photoshop, and built using Backbone.js.</p>
            </Section>

            <Section
                {...props}
                visual={ <Image alt="A collection of screens from the mobile web experience of Thumbcinema" src={{
                        small: require('./images/thumbcinema/2.jpg'),
                        large: require('./images/thumbcinema/2@2x.jpg')
                }}/> }
                heading="UI animation at its core">
                    <p>One of the goals of Thumbcinema was to explore a user interface that doesn't lean on text as a means to provide feedback to users.</p>
                    <p>When creating a flipbook with Thumbcinema, all UI elements communicate via animation. Create a new page, and it flows in from the right with existing pages shifted over. Delete a page, and it falls from the stage.</p>
                    <p>Users don't need to be told what's going on, because they're shown in physical terms how the UI has been updated.</p>
                    <p>I also experimented with different ways to play back animations. A simple play button was included, but I wanted to recreate the feeling of flipping through a book with your fingers.</p>
                    <p>To do this, created a mode of playback where you could manually move forward or backwards in a flipbook by making a circle gesture with your mouse — clockwise to move forward and anti-clockwise to move backwards, with the speed determining playback speed.</p>
            </Section>

            <Section
                {...props}
                visual={ <Image alt="The creation experience for Thumbcinema" src={{
                        small: require('./images/thumbcinema/3.jpg'),
                        large: require('./images/thumbcinema/3@2x.jpg')
                }}/> }
                heading="It got lots of love">
                    <Feedback items={feedback} />
            </Section>
        </>
    )
}